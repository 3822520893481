
import { defineComponent } from 'vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'AboutUs',
  components: {
    PageWithHeader,
    Pic,
  },
  setup () {
    const officeList = [{
      icon: 'user/about/office_1',
      country: 'UK',
      countryLable: 'aboutus_6',
      address: 'Five Lamps Place Amiens Street, Dublin 1 lreland',
      addressLable: 'aboutus_7',
    }, {
      icon: 'user/about/office_2',
      country: 'South Africa',
      countryLable: 'aboutus_8',
      address: 'Five Lamps Place Amiens Street, Dublin 1 lreland',
      addressLable: 'aboutus_9',
    }, {
      icon: 'user/about/office_3',
      country: 'Australia',
      countryLable: 'aboutus_10',
      address: 'Five Lamps Place Amiens Street, Dublin 1 lreland',
      addressLable: 'aboutus_11',
    }]
    const numbersDataL1 = [{
      icon: 'user/about/numbers_1',
      number: '10000+',
      type: 'Daily new clients',
      typeLable: 'aboutus_13',
    }, {
      icon: 'user/about/numbers_2',
      number: '300000+',
      type: 'Active clients',
      typeLable: 'aboutus_14',
    }, {
      icon: 'user/about/numbers_3',
      number: '$80+million',
      type: 'Trading volume',
      typeLable: 'aboutus_15',
    }]
    const numbersDataL2 = [{
      icon: 'user/about/numbers_4',
      number: '3+',
      type: 'Years in the industry',
      typeLable: 'aboutus_16',
    }, {
      icon: 'user/about/numbers_5',
      number: '80+',
      type: 'Assets',
      typeLable: 'aboutus_17',
    }, {
      icon: 'user/about/numbers_6',
      number: '<10ms',
      type: 'Order execution',
      typeLable: 'aboutus_18',
    }]
    return {
      officeList,
      numbersDataL1,
      numbersDataL2,
    }
  },
})
